body {
  background-color: linear-gradient(
    90deg,
    rgba(50, 50, 50, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  ) !important;
}

html {
  min-height: 100%; /* Look, it's not fixed anymore! */

  display: flex;
  flex-direction: column;
}

body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
